import { ImLink } from 'react-icons/im';
import { useTheme } from '@mui/material/styles';
import { memo, useRef, type FC, useCallback } from 'react';
import { Stack, alpha, IconButton, Typography } from '@mui/material';

interface ITitleWithClipboard {
  id: string;
  title: string;
  isLoading: boolean;
  isCampaignPage: boolean;
}

const TitleWithCopy: FC<ITitleWithClipboard> = ({
  id,
  title,
  isLoading,
  isCampaignPage,
}) => {
  const buttonRef = useRef<null | HTMLButtonElement>(null);

  const theme = useTheme();

  const animateCopyIcon = useCallback(() => {
    if (buttonRef.current) {
      buttonRef.current.classList.add('animate');
      setTimeout(() => {
        if (buttonRef.current) {
          buttonRef.current.classList.remove('animate');
        }
      }, 300);
    }
  }, []);

  const copyLinkToClipboard = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      animateCopyIcon();
      const module = isCampaignPage ? 'automatic-campaign' : 'bonus-management';
      window.parent.postMessage(`${module}/${id}/edit`, '*');
    },
    [animateCopyIcon, id, isCampaignPage]
  );

  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography variant="h3">{title}</Typography>
      <IconButton
        size="medium"
        color="primary"
        ref={buttonRef}
        disabled={isLoading}
        onClick={copyLinkToClipboard}
        sx={{
          // TODO: Temporarily hide. Waiting platform implementation
          visibility: 'hidden',
          marginLeft: '0.5rem',
          position: 'relative',
          paddingTop: '0.75rem',
          display: 'inline-block',
          '&:hover': {
            background: 'none',
          },
          '&.animate .ripple': {
            animation: 'ripple-effect 0.6s ease-out',
          },
          '@keyframes ripple-effect': {
            '0%': {
              opacity: 1,
              transform: 'scale(0)',
            },
            '100%': {
              opacity: 0,
              transform: 'scale(1)',
            },
          },
          '& .ripple': {
            opacity: 0,
            width: '140%',
            height: '140%',
            top: '-0.25rem',
            left: '-0.5rem',
            borderRadius: '50%',
            position: 'absolute',
            transform: 'scale(0)',
            pointerEvents: 'none',
            backgroundColor: `${alpha(theme.palette.primary.main, 0.4)}`,
          },
        }}
      >
        <ImLink />
        <span className="ripple" />
      </IconButton>
    </Stack>
  );
};

export default memo(TitleWithCopy);

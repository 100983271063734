import { OPERATOR_TYPE } from '@features/filters/types';
import { type ITargetGroup } from '@modules/automaticCampaigns/types';

export const automaticCampaignsTextLabels = {
  ratesTooltip: 'View Rates',
  uploadControl: '.csv file max to 2 MB',
  uploadControlTitle: 'Click or Drag to upload file',
  accumulatedBetAmountTitle: 'Accumulated Trigger Conditions',
  accumulatedDepositAmountTitle: 'Accumulated Deposit Amount',
  excludedPlayers: 'Amount of Players excluded from the campaign:',
  applicablePlayers: 'Amount of Players applicable for the campaign:',
  unsavedChanges: 'There are unsaved changes, would you like to continue?',
  ratesDisabledTooltip: 'The Min Accumulated Amount and Brands must be filled',
  campaignRelationPopupInactiveItemTooltip:
    'Inactive campaign may disrupt the configured chain',
  uploadFileHint:
    'The file will be validated after completion of the creation/modification process.',
  changeBrandPopup:
    'The change will cause the reset of previously selected bonuses, sport conditions, and the campaign relation configurations. Continue?',
  enableDailyTimeRangesTooltip:
    'An option that allows to long-running campaigns to trigger offers only within the specified time slot of the day, such as happy hour campaigns.',
  campaignRelationTooltip:
    'With Campaign Relation configured, players become eligible for the campaign only if the related campaign has been activated within the specified period.',
  overrideBonusTimezoneTooltip:
    'When selected, upon granting the bonus from a campaign the system will use the timezone setting from the campaign and will overwrite the timezone defined on each of the selected bonuses within the campaign.',
  uploadFileTooltip:
    'Supported file format is CSV containing Player IDs only. Remember that each individual ID must be placed on a new and separate row. The uploaded file will be validated after completion of the campaign creation/modification process.',
  uploadTypeTooltipOptions: [
    `Adds the new data to an existing source file/table (if any). Any fields that don't exist in the current table design will be added.`,
    `Overwrites the data of the existing source file/table and load the new data into it.`,
  ],
  accumulatedBetAmountSwitchTooltip:
    'When enabled, the campaign will trigger when player reaches the specified amount by doing one or more bets. Once campaign triggered, the accumulated amount reset and new round of accumulation will start. The amount of the linked bonus will be calculated based on player`s accumulated amount.',
  accumulatedDepositAmountSwitchTooltip:
    'When enabled, the campaign will trigger when player reaches the specified amount by doing one or more deposits. Once campaign triggered, the accumulated amount reset and new round of accumulation will start. The amount of the linked bonus will be calculated based on player`s accumulated amount.',
};

export const initialFilters = {
  id: { value: '', inversion: false, operator: OPERATOR_TYPE.EQUAL },
  brands: { value: [], inversion: false, operator: OPERATOR_TYPE.IN },
  trigger: { value: [], inversion: false, operator: OPERATOR_TYPE.IN },
  bonusName: { value: '', inversion: false, operator: OPERATOR_TYPE.LIKE },
  betActionType: { value: [], inversion: false, operator: OPERATOR_TYPE.IN },
  enabled: { value: false, inversion: false, operator: OPERATOR_TYPE.EQUAL },
  campaignName: { value: '', inversion: false, operator: OPERATOR_TYPE.LIKE },
  targetGroup: { value: null, inversion: false, operator: OPERATOR_TYPE.EQUAL },
};

export const targetGroup: ITargetGroup = {
  SEGMENT: 'Segment',
  OPEN_FOR_ALL: 'Open for all',
};

import { lazy } from 'react';
import { type RouteObject } from 'react-router-dom';

import { ROUTES } from '@constants';
import { Suspendable } from '@components/common/Suspendable';

const ScheduledCampaigns = Suspendable(
  lazy(() =>
    import('@modules/scheduledCampaigns/controller').then((module) => ({
      default: module.ScheduledCampaignsController,
    }))
  )
);

const Campaign = Suspendable(
  lazy(() =>
    import(
      '@modules/scheduledCampaigns/submodules/scheduledCampaign/controller'
    ).then((module) => ({
      default: module.ScheduledCampaignController,
    }))
  )
);

export const ScheduledCampaignRoutes: RouteObject[] = [
  {
    element: <ScheduledCampaigns />,
    path: ROUTES.SCHEDULED_CAMPAIGNS.ROOT,
  },
  {
    element: <Campaign mode="create" />,
    path: ROUTES.SCHEDULED_CAMPAIGNS.CREATE,
  },
  {
    element: <Campaign mode="edit" />,
    path: ROUTES.SCHEDULED_CAMPAIGNS.EDIT,
  },
  {
    element: <Campaign mode="clone" />,
    path: ROUTES.SCHEDULED_CAMPAIGNS.CLONE,
  },
];

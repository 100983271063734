import { lazy } from 'react';
import * as Sentry from '@sentry/react';
import { type RouteObject } from 'react-router-dom';

import { ROUTES } from '@constants';
import { BonusRoutes } from '@routes/BonusRoutes';
import { IframeMode } from '@routes/IframeModeRoutes';
import { AppSettings } from '@routes/AppSettingsRoutes';
import { Suspendable } from '@components/common/Suspendable';
import { GiveBonusManagement } from '@routes/GiveBonusRoutes';
import { PlayerBonusesRoutes } from '@routes/PlayerBonusesRoutes';
import { ErrorComponent } from '@components/common/ErrorComponent';
import { ScheduledCampaignRoutes } from '@routes/ScheduledCampaigns';
import { CampaignMonitorRoutes } from '@routes/CampaignMonitorReport';
import { CampaignActivityRoutes } from '@routes/CampaignActivityRoutes';
import { AutomaticCampaignRoutes } from '@routes/AutomaticCampaignRoutes';

// Layouts
const MainLayout = Suspendable(
  lazy(() =>
    import('@layout/MainLayout').then((module) => ({
      default: module.MainLayout,
    }))
  )
);

// Modules ...

const ModuleRoutes: RouteObject = {
  path: ROUTES.ROOT,
  element: (
    <Sentry.ErrorBoundary fallback={<ErrorComponent />}>
      <MainLayout />
    </Sentry.ErrorBoundary>
  ),
  children: [
    ...BonusRoutes,
    ...GiveBonusManagement,
    ...AutomaticCampaignRoutes,
    ...ScheduledCampaignRoutes,
    ...PlayerBonusesRoutes,
    ...CampaignActivityRoutes,
    ...CampaignMonitorRoutes,
    ...AppSettings,
    ...IframeMode,
  ],
};

export default ModuleRoutes;

import { lazy } from 'react';
import { type RouteObject } from 'react-router-dom';

import { ROUTES } from '@constants';
import { Suspendable } from '@components/common/Suspendable';

const GiveBonusManagementController = Suspendable(
  lazy(() =>
    import('@modules/giveBonus/controller').then((module) => ({
      default: module.GiveBonusManagementController,
    }))
  )
);
export const GiveBonusManagement: RouteObject[] = [
  {
    path: ROUTES.GIVE_BONUS_MANAGEMENT.ROOT,
    element: <GiveBonusManagementController />,
  },
];

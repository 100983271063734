import { type TextFieldProps } from '@mui/material';

import { type Option } from '@shared/form/types';

export interface IFiltersConfig {
  order: number;
  label: string;
  enabled: boolean;
  fieldName: string;
  limitTags?: number;
  options?: Option[];
  multiple?: boolean;
  disabled?: boolean;
  defaultValue?: string;
  operator: OPERATOR_TYPE;
  isNumberInput?: boolean;
  sx?: TextFieldProps['sx'];
  formElement: FORM_ELEMENT;
  isFloatNumberInput?: boolean;
  withVirtualization?: boolean;
  sxControl?: TextFieldProps['sx'];
  possibleOperators: OPERATOR_TYPE[];
  inputProps?: TextFieldProps['InputProps'];
}

export enum FORM_ELEMENT {
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  CHECKBOX = 'CHECKBOX',
  DATE = 'DATE',
  DATE_RANGE_END = 'DATE_RANGE_END',
  DATE_RANGE_START = 'DATE_RANGE_START',
  DATE_TIME = 'DATE_TIME',
  INPUT = 'INPUT',
  SELECT = 'SELECT',
}

export enum OPERATOR_TYPE {
  EQUAL = 'EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
  IN = 'IN',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
  LIKE = 'LIKE',
  NOT_EQUAL = 'NOT_EQUAL',
  NOT_IN = 'NOT_IN',
  NOT_LIKE = 'NOT_LIKE',
}

export interface ISearchParams {
  page: number;
  size: number;
  sorts: ISortParams[];
  filters: IFiltersParams[];
  includeExternalBonuses?: boolean;
}

export interface IInitialFilter {
  value?: any;
  operator?: string;
  inversion: boolean;
}

export interface ISortParams {
  field: string;
  order: OPERATOR_SORT;
}
export interface IFiltersParams {
  value: any;
  field: string;
  inversion?: boolean;
  type: OPERATOR_TYPE;
}

export interface IPaginationConfig {
  pageSize: number;
  pageIndex: number;
  totalCount: number;
}

export enum OPERATOR_SORT {
  ASC = 'ASC',
  DESC = 'DESC',
}

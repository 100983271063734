import { lazy } from 'react';
import { type RouteObject } from 'react-router-dom';

import { ROUTES } from '@constants';
import { Suspendable } from '@components/common/Suspendable';

const CampaignMonitorReport = Suspendable(
  lazy(() =>
    import('@modules/campaignMonitorReport/controller').then((module) => ({
      default: module.CampaignMonitorReportController,
    }))
  )
);

export const CampaignMonitorRoutes: RouteObject[] = [
  {
    element: <CampaignMonitorReport />,
    path: ROUTES.CAMPAIGN_MONITOR_REPORT.ROOT,
  },
];

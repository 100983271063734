import { Suspense } from 'react';

import Loader from '@components/common/Loader/Loader';

export const Suspendable =
  <T extends Record<string, unknown>>(Component: React.FC<T>) =>
  (props: any) => (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );
